import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {LinkContainer} from 'react-router-bootstrap'



function CollapsibleExample() {
  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
      <Container fluid className='px-3'>
      <LinkContainer to="/Home">
      <Navbar.Brand>Earth of Education</Navbar.Brand>
      </LinkContainer>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <LinkContainer to="/Home">
              <Nav.Link>Home</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/About">
              <Nav.Link>AboutUS</Nav.Link>
            </LinkContainer>
            <NavDropdown title="Courses" id="collasible-nav-dropdown">
              <LinkContainer to="/Frontend">
              <NavDropdown.Item>Front-end</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/Backend">
              <NavDropdown.Item>Back-end</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/Fullstack">
              <NavDropdown.Item>Full-Stack</NavDropdown.Item>
              </LinkContainer>
              <NavDropdown.Divider />
              <LinkContainer to="/Tutorials">
              <NavDropdown.Item>Free Tutorial</NavDropdown.Item>
              </LinkContainer>
              <NavDropdown.Divider />
              <LinkContainer to="/English">
              <NavDropdown.Item>English</NavDropdown.Item>
              </LinkContainer>
            </NavDropdown>
            
          </Nav>
          <Nav>
          <LinkContainer to="/Blog">
            <Nav.Link>Blog</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/Contact">
            <Nav.Link  >Contact</Nav.Link>
            </LinkContainer>
          </Nav>
          <Form className="d-flex">
                  <Form.Control
                    type="search"
                    placeholder="Search"
                    className="me-2"
                    aria-label="Search"
                  />
                  <Button variant="outline-light">Search</Button>
          </Form>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default CollapsibleExample;