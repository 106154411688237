import React from 'react'
import { 
    TextField,
    Button,
    Grid,
    Stack, 
   } from '@mui/material'

const Form = () => {
  return (
      <Grid item sm={1} sx={{
            display: "flex",
            alignItems: "center", 
            justifyContent: "center"
        }}>
            <form>
            <Stack spacing={2} alignItems="center">     
                <TextField size='small' label="Full Name" sx={{width: {xs: "100%" , md: "200%" },}}/>
                <TextField size='small' label="Email" sx={{width: {xs: "100%" , md: "200%" },}}/>
                <TextField multiline label="Message" sx={{width: {xs: "100%" , md: "200%" },}}/>
                <Button variant="outlined" type="submit" sx={{width: {xs: "75%" , md: "150%" },}}>Submit</Button>
            </Stack>
            </form>
        </Grid>
  )
}

export default Form