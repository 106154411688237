import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button } from 'react-bootstrap';
import Stack from 'react-bootstrap/Stack';
import React, { useEffect } from 'react'
import "./Hero.css"
import lottie from "lottie-web"
import animationHero from "../../../src/Animation/Hero.json"


const Hero = () => {

  useEffect(() => {
    lottie.loadAnimation({
      container: document.querySelector("#react-hero"),
      animationData: animationHero,
      renderer: "svg", // "canvas", "html"
      loop: true, // boolean
      autoplay: true, // boolean
    });
  }, []);

  return (
    <Container className="main d-flex align-items-center justify-content-center" >
      <Row>
        <Col md={12} lg={6} className='text-center d-flex align-items-center justify-content-center'>
          <Stack gap={1}>
            <h1 className='fw-normal p-5'>
              Hello Welcome to Earth of Education
            </h1>
            <p className='text-justify fs-5 fw-lighter px-5'>
              Earth of Education is dedicated to helping working professionals and aspiring individuals
              reach their full potential. Our platform provides access to a wide range of online education
              courses, designed to help you advance your career and secure a quick and good job. We believe
              that education should be accessible to all, and we strive to make it easy for you to grow your
              skills and achieve your career goals. Join us today and take the first step towards a bright
              and successful future.
            </p>
            <div className='p-5'>
            <Button className='px-5' variant="outline-dark" size="lg">Join US</Button>
            </div>
          </Stack>
        </Col>
        <Col md={12} lg={6}>
          <div id="react-hero" style={{ width: 500, height: 500 }} />
        </Col>

      </Row>
    </Container>
  )
}

export default Hero
