import React from 'react'
import {Button, Container} from 'react-bootstrap'
import Image from 'react-bootstrap/Image'

const About = () => {
  return (
    <Container className='text-center'>
      <p className='fs-1 fw-Bold p-5'>About Us</p>
        <Image fluid src='https://cdn.unenvironment.org/styles/article_billboard_image/s3/2020-04/laboratory-3655511_1920.jpg?itok=J7L62eyM'></Image>
        <p className='fs-4 fw-lighter p-5'>
      Earth of Education" is a platform dedicated to providing education and training to individuals who are seeking to upgrade their careers and enhance their skills with the latest technology. Our courses are designed to equip students with the knowledge and expertise required to pursue high-paying job opportunities in today's job market.
      </p>
      <Button className='px-5 mb-5' variant="outline-dark" size="lg">Join US</Button>
      <hr className='mb-5 p-5' />
      
      
      
      <Image fluid src='https://www.pathfinderforme.com/wp-content/uploads/2020/11/qtq80-n0JD7V.jpeg'></Image>
      <p className='fs-3 fw-lighter p-5'>
      We offer comprehensive courses in web development, robotics, AI and ML, graphics designing, video editing, and other in-demand technologies. Our teaching approach is different from the others as we go beyond just providing PDF notes and videos. We also offer live classes for every session to ensure that students have the opportunity to ask questions and resolve any doubts they may have.
      </p>
      <Button className='px-5 mb-5' variant="outline-dark" size="lg">Join US</Button>
      <hr className='mb-5 p-5' />

      <Image fluid src='https://img.jagranjosh.com/imported/images/E/Articles/How-to-set-smart-career-goals-for-professional-success-body-image.jpg'></Image>
      <p className='fs-3 fw-lighter p-5'>
      At Earth of Education, our goal is to help individuals achieve their professional goals by providing them with the latest knowledge and skills in cutting-edge technologies. Whether you're a beginner or an experienced professional, our courses are designed to cater to your individual needs and provide you with the necessary tools to succeed. Join us today and take your first step towards a brighter future.
      </p>
      <Button className='px-5 mb-5' variant="outline-dark" size="lg">Join US</Button>
      <hr className='mb-5 p-5' />

    </Container>
  )
}

export default About
