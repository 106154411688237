import React from 'react'

const English = () => {
  return (
    <div>
      <h1>Comming Soon English</h1>
    </div>
  )
}

export default English
