import React from 'react'
import { 
    Typography,
    Grid,
    Stack, 
   } from '@mui/material'

const Details = () => {
  return (
        <Grid item sm={1} sx={{
            display: "flex",
            alignItems: "center", 
            justifyContent: "center"
        }}>
            <Stack>
            <Typography variant='h3' align='center' sx={{mb: "10%"}}>
                CONTACT
            </Typography>
            <Typography variant='h6' align='left'>
                52/1 24th main,<br/>
                HSR Layout,<br/>
                Bengaluru, KA 560102<br/>
                contactvivekray@gmail.com<br/>
                +91 7903217827
            </Typography>
            </Stack>
        </Grid>
  )
}

export default Details