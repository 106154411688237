import React from 'react'

const Fullstack = () => {
  return (
    <div>
      <h1>Comming Soon FullStack</h1>
    </div>
  )
}

export default Fullstack
