import Navbar from "./Components/NavBar/Navbar"
import Hero from "./Components/HeroPage/Hero";
import About from "./Components/AboutUs/About";
import Blog from "./Components/Blog/Blog";
import Frontend from "./Components/Courses/FrontEnd/Frontend";
import Backend from "./Components/Courses/BackEnd/Backend";
import Fullstack from "./Components/Courses/FullStack/Fullstack";
import English from "./Components/Courses/English/English";
import Tutorials from "./Components/Courses/Tutorials/Tutorials";
import Contact from "./Components/Contact/Contact";
import { BrowserRouter, Routes, Route, } from "react-router-dom";


function App() {
  return (
    <>
    <BrowserRouter>
        <Navbar/>
       <Routes>
      <Route index element={<Hero />} />
      <Route path='Home' element={<Hero />} />
      <Route path='About' element={<About />} />
      <Route path='Blog' element={<Blog />} />
      <Route path='Frontend' element={<Frontend />} />
      <Route path='Backend' element={<Backend />} />
      <Route path='Fullstack' element={<Fullstack />} />
      <Route path='English' element={<English />} />
      <Route path="Tutorials" element={<Tutorials />}/>
      <Route path='Contact' element={<Contact />} />
      </Routes>  
    </BrowserRouter>
    </>
  );
}

export default App;
