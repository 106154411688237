import React from 'react'

const Tutorials = () => {
  return (
    <div>
      <h1>Comming Soon Tutorials</h1>
    </div>
  )
}

export default Tutorials
