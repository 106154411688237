import React from 'react'

const Backend = () => {
  return (
    <div>
      <h1>Comming Soon Backend</h1>
    </div>
  )
}

export default Backend
